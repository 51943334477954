import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { useState, useEffect } from "react";
import "./App.css";
import { TailSpin } from "react-loader-spinner";
import {
  isHLSProvider,
  MediaPlayer,
  MediaProvider,
  Poster,
} from '@vidstack/react';
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default';
import Logger from './Logger';
import HLS from 'hls.js'

const PROXY_HOSTNAME = 'cdn-proxy.corrsy.workers.dev';

export default function BunnyPlayer({bunnyID, cdnHostname, v}) {
  const [isLoading, setLoading] = useState(true);
  const [counter, setCounter] = useState(0);
  const [logs, setLogs] = useState([]);
  const [showBackup, setShowBackup] = useState(false);
  const [backupSelected, setBackupSelected] = useState(false);
  const [proxyHostname, setProxyHostname] = useState(null);
  const bunnyURL = bunnyID && cdnHostname && [
    'https:/',
    proxyHostname,
    cdnHostname,
    bunnyID,
    'playlist.m3u8',
  ].filter(Boolean).join('/');

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);

    setInterval(() => setLogs([...Logger.allLogs]), 100);

    const xhr = new XMLHttpRequest();
    xhr.addEventListener(
      'load',
      e => Logger.log('===HEADERS START====\n', e.target.response, '\n===HEADERS END===='),
    );

    fetch('https://api.ipify.org/?format=json')
      .then(res => res.json())
      .then(json => {
        Logger.log(`IP = ${json.ip}`);
        xhr.open('get', 'https://corrsy-proxy-bunny-iframe.murtadha-altameemi2156.workers.dev');
        xhr.send();
      });
  }, []);

  const callbacks = tag => {
    const res = {};
    [
      'onHlsManifestLoading',
      'onHlsManifestLoaded',
      'onHlsManifestParsed',
      'onHlsMediaAttached',
      'onHlsMediaAttaching',
      'onHlsMediaDetached',
      'onHlsMediaDetaching',
      'onError',
      'onHlsError',
      'onHlsLibLoadError',
      'onHlsLibLoadStart',
      'onHlsLibLoaded',
      'onLoadStart',
      'onStarted',
      'onEnd',
      'onProgress',
      'onAutoPlayFail',
      'onMediaPlayRequest',
    ].map(cb => res[cb] = e => Logger.log(`${cb}(${tag}):`, e));

    return res;
  };

  const callbacks2 = (tag) => ({
    onError: e => console.log(`onError(${tag}):`, bunnyID, e) || setShowBackup(true),
    onHlsError: e => Logger.log(`onHlsError(${tag}):`, bunnyID, e) || setShowBackup(true),
    onHlsLibLoadError: e => Logger.log(`onHlsLibLoadError(${tag}):`, bunnyID, e),
    onHlsLibLoadStart: e => Logger.log(`onHlsLibLoadStart(${tag}):`, e),
    onHlsLibLoaded: e => Logger.log(`onHlsLibLoaded(${tag})`),
    onLoadStart: e => Logger.log(`onLoadStart(${tag}):`, e),
    onStarted: e => Logger.log(`onStarted(${tag}):`, e),
    onEnd: e => Logger.log(`onEnd(${tag}):`, e),
    onProgress: e => Logger.log(`onProgress(${tag}):`, e),
    onAutoPlayFail: e => Logger.log(`onAutoPlayFail(${tag}):`, e),
    onMediaPlayRequest: e => Logger.log(`onMediaPlayRequest(${tag}):`, e),
  })

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading && (
        <TailSpin
          visible={isLoading}
          height="80"
          width="80"
          color="black"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        />
      )}
      {counter > 3 && <Logger.ConsoleLog logs={logs} />}
      {bunnyURL && (
        <div
          style={{
            width: "100vw",
            maxHeight: "65vh",
            marginTop: '4rem',
            border: 0,
          }}>
          <div style={{flexDirection: 'row', justifyContent: 'space-around', display: 'flex'}}>
            <button
              onClick={() => setCounter(counter + 1)}
              style={{color: 'gray', userSelect: 'none'}}>
              v{(10 + (v ?? 0))}
            </button>
            {showBackup && !proxyHostname && (
              <div>
                <a
                  href="#"
                  // onClick={() => setBackupSelected(true) || setLoading(true)}>
                  onClick={() => setLoading(true) || setProxyHostname(PROXY_HOSTNAME)}>
                  اذا تتواجه مشكلة بتحميل هذي الصفحة،
                  <br />
                  اضغط هنا حتى تجرب سيرفر اخر
                </a>
              </div>
            )}
          </div>
          {!backupSelected && (<MediaPlayer
            load="eager"
            posterLoad="eager"
            playsInline
            src={bunnyURL}
            {...callbacks('Player')}
            {...callbacks2('Player')}
            onLoad={e => {
              Logger.log(`onLoad:`, e);
              setLoading(false);
            }}
            onLoadedData={e => {
              Logger.log(`onLoadedData:`, e);
              setLoading(false);
            }}
            onProviderChange={provider => {
              if (isHLSProvider(provider)) {
                provider.library = HLS;
              }
            }}
          >
            <MediaProvider onError={callbacks2('Provider').onError}>
              <Poster className="vds-poster" src={bunnyURL.replace('playlist.m3u8', 'thumbnail.jpg')} />
            </MediaProvider>
            <DefaultVideoLayout icons={defaultLayoutIcons} />
          </MediaPlayer>)}
          {backupSelected && (
            <BackupPlayer
              bunnyID={bunnyID}
              cdnHostname={cdnHostname}
              setLoading={setLoading}
            />
          )}
        </div>
      )}
    </div>
  );
}

function BackupPlayer({bunnyID, cdnHostname, setLoading}) {
  return (
    <iframe
      title="Bunny Video"
      src={`https://corrsy-proxy-bunny-iframe.murtadha-altameemi2156.workers.dev/?` + [
        `videoID=${bunnyID}`,
        `cdnHostname=${cdnHostname}`,
      ].join('&')}
      loading="lazy"
      allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
      allowFullScreen={true}
      onLoad={() => setLoading(false)}
    />
  );
}