import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { useState, useEffect } from "react";
import "./App.css";
import { useParams } from "react-router-dom";
import BunnyPlayer from './BunnyPlayer';

function App() {
  const { videoURL } = useParams();
  const [bunnyURL, setBunnyURL] = useState();

  useEffect(() => {
    getBunnyURL(videoURL.replace(/x/g, '')).then(res => {
      if (!res) {
        alert('Failed to get bunnyURL for ' + videoURL);
      } else {
        setBunnyURL(res);
      }
    });
  }, [videoURL]);

  return (
    <BunnyPlayer
      bunnyID={bunnyURL?.replace(/.*\/([^/]*)\/playlist.*/, '$1')}
      cdnHostname={bunnyURL?.replace(/https:\/\/([^/]*)\/.*/, '$1')}
    />
  );
}

const PUBLIC_TOKEN =
  "patNzZDfGAY3eVfzO.d5fdc23bc13d6b8ae3324d081d0f049e639df7f755c0b2bc9c802efa1c913062";

async function getBunnyURL(vimeoID) {
  const filter =
    "filterByFormula=" + encodeURIComponent(`vimeoID="${vimeoID}"`);
  const res = await fetch(
    `https://api.airtable.com/v0/appeLkcLdQ8ZHvop8/tbl2ke8RdErlp2rdd?maxRecords=1&${filter}`,
    { headers: { Authorization: `Bearer ${PUBLIC_TOKEN}` } }
  );
  const json = await res.json();
  return json?.records[0]?.fields.hlsUrl;
}

export default App;