// import consolere from 'console-remote-client';
// consolere.connect({
//   server: 'https://console.re', // optional, default: https://console.re
//   channel: 'corrsy', // required
//   redirectDefaultConsoleToRemote: true, // optional, default: false
// });

const allLogs = [];
const log = (...args) => {
  allLogs.push(args);
  // console?.re?.log && console.re.log(...args);
  // console.log(...args);
}

(function() {
  const originalOpen = XMLHttpRequest.prototype.open;
  const originalSend = XMLHttpRequest.prototype.send;
  
  XMLHttpRequest.prototype.open = function(method, url) {
    this._url = url.replace('https://', '');
    this._method = method;
    const file = url.replace(/.*\/(\w+)/, '$1')
    this._file = this._file === this._url ? '' : file;
    log(`[${method.toUpperCase()}] ${this._file} ${this._url}`);
    
    originalOpen.apply(this, arguments);
  };
  
  XMLHttpRequest.prototype.send = function(body) {
    const xhr = this;
    xhr.addEventListener('error', function() {
    log(`Unknown network error:`, xhr._file, xhr._url, xhr);
    });
  
    xhr.addEventListener('timeout', function() {
    log('Request timeout:', xhr._file, xhr._url, xhr);
    });
  
    xhr.addEventListener('abort', function() {
    log('Request aborted:', xhr._file, xhr._url, xhr);
    });
  
    xhr.addEventListener('load', function() {
    const statusText = xhr.statusText && ` - ${xhr.statusText}`;
    if (xhr.status < 200 || xhr.status >= 300) {
      log(`Request failed (status = ${xhr.status + statusText}):`, xhr._file, xhr._url);
    } else {
      log(`(${xhr.status}) ${xhr._method} - ${xhr._file} ${xhr._url}`);
    }
    });
  
    originalSend.call(xhr, body);
  };
})();

window.addEventListener(
  'error',
  (...args) => log(
    `Window Error (src=${stringifyElement(args?.[0]?.target)}): `,
    args[0].message,
    ...args
  ),
  true,
);
window.addEventListener(
  'unhandledrejection',
  (...args) => log('Unhandled Rejection: ', ...args),
  true,
);

const stringifyElement = elem => {
  if (!elem || !elem.getAttribute) {
    return elem?.toString();
  }

  return (
    elem.tagName?.toLowerCase() + '.' +
    elem.getAttribute('class') + '#' +
    (elem.getAttribute('id') ?? '_')
  );
}

function ConsoleLog({ logs }) {
  const containerStyle = {
    maxHeight: '25rem',
    overflowY: 'scroll',
    border: '1px solid #ddd',
    padding: '10px',
    marginBottom: '10px',
    fontFamily: 'monospace',
    whiteSpace: 'pre',
  };

  return (
    <div style={{color: 'white', background: 'black'}}>
      <div style={containerStyle}>
        {logs.map((log, index) => (
          <div key={index}>
            {parseStyledLog(log).map((part, partIndex) => (
              <span key={partIndex} style={{ cssText: part.style }}>
                {parseObject(part.message, 1)}{' '}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function parseObject(obj, level) {
  if (level > 3) return obj.toString();

  if (obj && obj.toString() === '[object TimeRanges]') {
    return `{length: ${obj.length} start: ${obj.length && obj.start(0)}, end: ${obj.length && obj.end(0)}}`
  }

  if (!obj || typeof obj !== 'object' || Object.getPrototypeOf(obj) !== Object.prototype) {
    // if (obj?.toString() !== '[object Object]')
      return obj?.toString();
  }

  let str = '{\n';
  const padding = new Array(level + 1).join('  ');
  for (let k of Object.keys(obj)) {
    str += `${padding}${k}: ${parseObject(obj[k], level + 1)}\n`;
  }

  return str + Array(level).join('  ') + '}';
}

function parseStyledLog(logArgs) {
  const result = [];
  let currentIndex = 0;

  while (currentIndex < logArgs.length) {
    if (typeof logArgs[currentIndex] === 'string' && logArgs[currentIndex].startsWith('%c')) {
      const style = logArgs[currentIndex + 1];
      const message = logArgs[currentIndex].replace('%c', '');
      result.push({ message, style });
      currentIndex += 2;
    } else {
      result.push({ message: logArgs[currentIndex], style: '' });
      currentIndex += 1;
    }
  }

  return result;
};

const exports = {log, allLogs, ConsoleLog};
export default exports;