import { useState } from "react";
import "./App.css";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useEffect } from "react";
function Castr() {
  const { videoURL } = useParams();
  let castrURL = "https://player.castr.com/vod/" + videoURL + "?speed=true";
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 20000);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        right: "0",
        width: "100%",
        height: "65%",
        background: "black",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading && (
        <TailSpin
          visible={isLoading}
          height="80"
          width="80"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        />
      )}
      <iframe
        src={castrURL}
        title="castrvideo"
        style={{
          width: "100%",
          height: "100%",
        }}
        frameborder="0"
        allow="autoplay"
        allowFullScreen
        webkitallowfullscreen
        mozallowfullscreen
        oallowfullscreen
        msallowfullscreen
        onLoad={() => {
          setLoading(false);
        }}
      ></iframe>
    </div>
  );
}

export default Castr;
